import React from 'react';
import './index.scss';

const Logo = () => (
  <div className="main-logo">
    <img src="/images/logo.svg" width="130" alt="" />
  </div>
);

export default Logo;
