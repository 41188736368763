/* eslint-disable jsx-a11y/media-has-caption */
import React, {createRef, useState, useEffect, useContext} from 'react';
import useInterval from '@use-it/interval';
import ScheduleContext from '../schedule-context';
import moment from 'moment';

import './index.scss';

const TOTAL_OFFSET = 283;

const UPDATE_INTERVAL = 10000;

const PLAY_BUTTON = (
  <svg viewBox="0 0 34 42"
    xmlns="http://www.w3.org/2000/svg"
    className="play-icon">
      <path d="m0 0v42l34-21z" fillRule="evenodd"/>
  </svg>);

export const RadioPlayer = ({src}) => {
  const audioRef = createRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [offset, setOffset] = useState(TOTAL_OFFSET);

  const {store: { schedule }} = useContext(ScheduleContext);

  const startsAt = schedule.current?.starts;
  const endsAt = schedule.current?.ends;

  const updateOffset = () => {
    let newOffset = TOTAL_OFFSET;

    if (!schedule.current) {
      return;
    }

    const {starts, ends} = schedule.current;

    if (starts && ends) {
      const now = (new Date()).getTime();
      const end = (new Date(ends)).getTime();
      const start = (new Date(starts)).getTime();

      const total = end - start;
      const left = end - now;

      newOffset = Math.max(TOTAL_OFFSET * (left / total), 0);
    }

    setOffset(newOffset);
  }

  useInterval(updateOffset, UPDATE_INTERVAL);

  const play = () => {
    if (!isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    document.body.classList.toggle('is-playing-radio', !isPlaying);
    setIsPlaying(!isPlaying);
  }

  useEffect(() => {
    updateOffset();
  }, [schedule]);

  useEffect(updateOffset, [endsAt]);

  return (
    <div className={`radio-player${isPlaying ? ' is-playing' : ''}`}>
      <svg
        className="circle"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeDashoffset: offset }}
      >
        <circle cx="50" cy="50" r="45" />
      </svg>
      <button
        aria-label="Play or pause radio player"
        onClick={play}
        type="button"
        title={(isPlaying ? 'Pause' : 'Play') +
          (startsAt ? ` (started ${moment(startsAt).fromNow()})` : '')}
      >
        {isPlaying ? '❙❙' : PLAY_BUTTON}
      </button>
      <audio ref={audioRef} src={src} />
    </div>
  );
};

export default RadioPlayer;
