import React, { useState, useContext } from 'react';
import Moment from 'react-moment';
import useInterval from '@use-it/interval';
import ScheduleContext from '../schedule-context';

import './index.scss';

const TIME_TOGGLE_INTERVAL = 6000;

const Copy = {
  ANY_MINUTE_NOW: 'Starts soon',
  WILL_RESUME_SHORTLY: 'Archive playing',
};

const decodeTitle = (name) => {
  const el = document.createElement('div');
  el.innerHTML = name;
  return el.innerText;
};

function Schedule() {
  const [isDetailed, setIsDetailed] = useState(false);
  useInterval(() => setIsDetailed(!isDetailed), TIME_TOGGLE_INTERVAL);

  const {
    store: { schedule, lastUpdated },
  } = useContext(ScheduleContext);

  let title = Copy.WILL_RESUME_SHORTLY;
  let endsAt = '';
  let nextStartsAt = '';
  let nextTitle = '';
  const initialized = !!lastUpdated;

  if (schedule.currentShow) {
    title = decodeTitle(schedule.currentShow[0].name);
    endsAt = schedule.currentShow[0].ends;

    if (title === 'Archive' && schedule.current.name) {
      title += schedule.current.name;
    }
  }

  if (schedule.next) {
    nextTitle = decodeTitle(schedule.nextShow[0].name);
    nextStartsAt = schedule.nextShow[0].starts;
  }

  title = title.replace('&amp;', '&')
    .replace('&apos;', "'")
    .replace('&quot;', '"');
  nextTitle = nextTitle.replace('&amp;', '&')
    .replace('&apos;', "'")
    .replace('&quot;', '"');

  const isShowAnyMinuteNow = () => nextStartsAt && new Date(nextStartsAt) < new Date();

  const nextTime = isShowAnyMinuteNow() || !initialized
    ? (<span>{Copy.ANY_MINUTE_NOW}</span>)
    : <Moment fromNow>{nextStartsAt || 0}</Moment>;

  return (
    <div className={`schedule-container${initialized ? '' : ' is-hidden'}`}>
      <div className="schedule-current">
        <div
          aria-live="polite"
          className={`schedule-title alpha${endsAt ? ' is-live' : ''}${
            title.length > 30 ? ' is-long' : ''}`}
          role="status"
        >
          {title}
        </div>
      </div>
      <div className="schedule-upcoming">
        <div className={`time-container ${isDetailed ? 'is-detailed' : ''}`}>
          <Moment format="HH:mm" className="upcoming-time">
            {nextStartsAt || 0}
          </Moment>
          <div className="time-explained">{nextTime}</div>
        </div>
        <div className="schedule-next-title">{nextTitle}</div>
      </div>
    </div>
  );
}

export default Schedule;
