import React from 'react';
import ScrollIntoView from 'scroll-into-view';
import './index.scss';

const WINDOW_SETTINGS = 'menubar=no,height=800,width=600,'
  + 'location=no,resizable=yes,scrollbars=yes,status=yes';

const goToResidents = (ev) => {
  ev.preventDefault();
  const section = document.querySelector('.residents-section');
  ScrollIntoView(section);
};

const openChatroom = (ev) => {
  ev.preventDefault();
  const { href } = ev.target;
  window.open(href, 'chat', WINDOW_SETTINGS);
};

const Menu = () => (
  <ul className="main-menu">
    <li aria-label="Open chatroom">
      <a href="http://netil-radio.chatango.com/" onClick={openChatroom}>
        Chatroom
      </a>
    </li>
    <li>
      <a href="#residents" onClick={goToResidents}>Residents</a>
    </li>
    <li>
      <a
        href="https://netilradio.substack.com/welcome"
        target="_blank"
        rel="noopener noreferrer"
      >
        Newsletter
      </a>
    </li>
  </ul>
);

export default Menu;
