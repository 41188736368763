import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import RadioPlayer from '../components/radio-player';
import Schedule from '../components/schedule';
import Logo from '../components/logo';
import Menu from '../components/menu';

import '../styles/global.scss';
import './index.scss';

const Url = {
  RADIO: 'https://netilradio.out.airtime.pro/netilradio_a',
};

const Meta = {
  TITLE: 'Netil Radio',
  DESCRIPTION: 'Real life radio from residents, shows and DJ’s with'
    + ' stories and anecdotes of lives spent in music.',
};

const getRadioOnCopy = (platform) => `Netil Radio on ${platform}`;
function IndexPage({ data: { allShow } }) {
  return (
    <>
      <Helmet>
        <title>{Meta.TITLE}</title>
        <meta property="og:url" content="http://www.netilradio.com" />
        <meta property="og:title" content={Meta.TITLE} />
        <meta name="description" content={Meta.DESCRIPTION} />
        <meta property="og:description" content={Meta.DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="http://www.netilradio.com/images/fbshare.png" />
      </Helmet>
      <header className="narrow-width">
        <Logo />
        <Menu />
      </header>
      <main>
        <section className="top-area narrow-width">
          <div className="content-group">
            <div className="radio-area">
              <RadioPlayer src={Url.RADIO} />
              <Schedule />
            </div>
            <div className="dots-container">
              <div className="dots" role="presentation" />
              <div className="dots" role="presentation" />
              <div className="dots" role="presentation" />
              <div className="dots" role="presentation" />
            </div>
          </div>
        </section>
        <section className="residents-section" id="residents">
          <div className="narrow-width residents-section-inner">
            <h3>Residents</h3>
            <ul className="residents-list">
              {allShow.nodes.map(({ title, url }) => <li key={title}><a href={url || '#'} target="_blank" rel="noreferrer">{title}</a></li>)}
            </ul>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="footer-inner narrow-width">
          <div className="text">
            <p>
              Community broadcasting
              <br />
              Hackney, London
            </p>
            <p>
              London&apos;s community voice broadcasting live from a converted
              shipping container atop of Netil Market.
            </p>
            <p>
              Site designed and built by
              {' '}
              <a

                href="https://goodpraxis.coop"
                target="_blank"
                rel="noopener noreferrer"
              >
                Good Praxis

              </a>
              .
            </p>
            <ul className="social-media">
              <li>
                <a
                  href="https://twitter.com/netilradio/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={getRadioOnCopy('Twitter')}
                >
                  <img src="/images/twitter.svg" alt="Twitter" loading="lazy" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/netilradio/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={getRadioOnCopy('Facebook')}
                >
                  <img src="/images/facebook.svg" alt="Facebook" loading="lazy" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/netilradio/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={getRadioOnCopy('Instagram')}
                >
                  <img
                    src="/images/instagram.svg"
                    alt="Instagram"
                    loading="lazy"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.mixcloud.com/NetilRadio/"
                  target="_blank noreferrer"
                  rel="noopener"
                  title={getRadioOnCopy('Mixcloud')}
                >
                  <img src="/images/mixcloud.svg" alt="Mixcloud" loading="lazy" />
                </a>
              </li>
            </ul>
          </div>
          <ul className="footer-logos">
            <li>
              <a
                href="https://eatworkart.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src="/images/logo-ewa.svg"
                  title="Eat Work Art"
                  alt="Eat Work Art"
                  loading="lazy"
                />
              </a>
            </li>
            <li>
              <a
                href="https://goodpraxis.coop"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src="/images/logo-gp-filled.svg"
                  title="Made by Good Praxis"
                  alt="Good Praxis logo"
                  loading="lazy"
                />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allShow {
      nodes {
        title
        url
      }
    }
  }
`;

export default IndexPage;
